import { useContext, useMemo } from 'react';
import ClientContext from '@/contexts/client.context';
import { LogosLine, Logo, SplitBar } from './styles';
import getImgSrcWorkaround from '@/utils/images';
import Image from 'next/image';
import cn from 'classnames';

export const Logos = ({
  logos,
  centered = false,
  showSwordLogo = false,
  headerContent = false,
}) => {
  const finalLogos = useMemo(() => {
    return showSwordLogo ? logos?.slice(0, 2) : logos?.slice(0, 3);
  }, [showSwordLogo, logos]);

  const { client } = useContext(ClientContext);

  if (!finalLogos && !showSwordLogo) {
    return null;
  }

  return (
    <LogosLine
      className={cn({
        centered: centered,
        headerContent: headerContent,
        platform: client?.product?.key === 'platform',
      })}
    >
      {finalLogos &&
        finalLogos.length > 0 &&
        finalLogos.map((logo, index) => {
          if (
            logo?.name === 'sword__colored.svg' ||
            logo?.attributes?.name === 'sword__colored.svg'
          ) {
            return (
              <>
                <Logo
                  key={!client?.product?.logo ? 'sword-logo' : 'sub-brand-logo'}
                  className={
                    !client?.product?.logo || client?.product?.key === 'platform'
                      ? 'sword-logo'
                      : 'sub-brand-logo'
                  }
                  $logoCount={showSwordLogo ? finalLogos.length + 1 : finalLogos.length}
                >
                  <Image
                    id={`header-logo-${index}`}
                    src={client?.product?.logo ?? '/sword__colored.svg'}
                    alt={client?.product?.key ?? 'Sword Health'}
                    loading="eager"
                    width={200}
                    height={client?.product?.logo ? 48 : 28}
                  />
                </Logo>
                {index < finalLogos.length - 1 && <SplitBar />}
              </>
            );
          }

          return (
            <>
              <Logo
                key={logo?.url ?? logo?.attributes?.url}
                className="client-logo"
                $logoCount={showSwordLogo ? finalLogos?.length + 1 : finalLogos?.length}
              >
                <Image
                  id={`header-logo-${index}`}
                  alt={logo?.alternativeText ?? logo?.attributes?.alternativeText}
                  src={getImgSrcWorkaround(logo?.url ?? logo?.attributes?.url)}
                  loading="eager"
                  width={224}
                  height={64}
                />
              </Logo>
              {index < finalLogos.length - 1 && <SplitBar />}
            </>
          );
        })}
      {showSwordLogo && (
        <>
          {finalLogos?.length > 0 && <SplitBar />}
          <Logo
            className={
              !client?.product?.logo || client?.product?.key === 'platform'
                ? 'sword-logo'
                : 'sub-brand-logo'
            }
            $logoCount={showSwordLogo ? finalLogos?.length + 1 : finalLogos?.length}
          >
            <Image
              id={'header-sword-logo'}
              src={client?.product?.logo ?? '/sword__colored.svg'}
              alt={client?.product?.key ?? 'Sword Health'}
              loading="eager"
              width={200}
              height={client?.product?.logo ? 48 : 28}
            />
          </Logo>
        </>
      )}
    </LogosLine>
  );
};
