import styled, { css } from 'styled-components';

export const LogosLine = styled.div`
  display: flex;
  width: 100%;
  row-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 24px;
  }

  &.centered {
    justify-content: center;
  }

  &.headerContent {
    padding-bottom: 12px;
  }

  &.platform {
    padding: 80px 0 40px 0;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      padding-top: 64px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      padding-top: 40px;
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ${({ $logoCount }) =>
    $logoCount > 2 &&
    css`
      width: calc(
        (100% - 42px * (${(props) => props.$logoCount - 1})) / ${(props) => props.$logoCount}
      );
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    ${({ $logoCount }) =>
      $logoCount > 2 &&
      css`
        width: calc(
          (100% - 22px * (${(props) => props.$logoCount - 1})) / ${(props) => props.$logoCount}
        );
      `};
  }

  img {
    height: auto;
    width: auto;
    object-fit: contain;
  }

  &.client-logo img {
    max-height: 64px;
    max-width: ${(props) => (props.$logoCount < 3 ? `224px` : `100%`)};
    min-height: ${(props) => (props.$logoCount < 3 ? `33px` : `auto`)};

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-width: ${(props) => (props.$logoCount < 3 ? `160px` : `100%`)};
      max-height: 48px;
    }
  }

  &.sword-logo img {
    max-height: 28px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-height: 20px;
    }
  }

  &.sub-brand-logo img {
    max-height: 48px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      max-height: 32px;
    }
  }
`;

export const SplitBar = styled.div`
  height: 32px;
  width: 2px;
  background: ${(props) => props.theme.colors.grey.light};
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
